import React from "react";
import { graphql } from "gatsby";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";

import Meta from "components/Meta";
import Layout from "components/Layout";
import ContactForm from "components/ContactForm";
import { DefaultMetaType } from "modules/meta/types";
import { NewsletterPopupType } from "modules/newsletter/types";

type ContactPageProps = { data: QueryTypes; location: any };

type QueryTypes = {
  contentstackNewsletterPopup: NewsletterPopupType;
  contentstackGlobalMeta: DefaultMetaType;
  contentstackContactPage: {
    title: string;
    meta: {
      window_title: string;
      meta_description: string;
    };
    newsletter: {
      show_popup_page: string;
    };
    contact: {
      title: string;
      description?: string;
    };
  };
};

const ContactPage: React.FC<ContactPageProps> = ({ data, location }) => {
  /* Newsletter Popup */
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.contentstackContactPage;

  const contactTitle = data?.contentstackContactPage?.contact?.title
    ? data?.contentstackContactPage?.contact?.title
    : "Contact us";
  const contactDescription = data?.contentstackContactPage?.contact?.description;

  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    submitted: newsletter?.submitted,
    pageOverride: page?.newsletter?.show_popup_page,
  });

  return (
    <Layout>
      <Meta defaultMeta={data.contentstackGlobalMeta} location={location} meta={data.contentstackContactPage.meta} />
      <div style={{ height: "100%" }}>
        <ContactForm title={contactTitle} description={contactDescription} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    contentstackContactPage(id: { eq: $id }) {
      id
      ...metaContactPage
      newsletter {
        show_popup_page
      }
      contact {
        title
        description
      }
    }
  }
`;

export default ContactPage;
